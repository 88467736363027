import { createSlice } from "@reduxjs/toolkit";

import { InvitationsAPI } from "../../services/invitations/invitations.service";
import { Invitations } from "../../services/invitations/dto/invitations.dto";
import { RootState } from "../../index";

interface invitationState {
	landingList: Invitations[];
	selectedInvitation: Invitations | undefined;
}

const initialState: invitationState = {
	landingList: [],
	selectedInvitation: undefined
};

const InvitationsSlice = createSlice({
	name: "invitations",
	initialState,
	reducers: {},
	extraReducers: builder =>
		builder
			.addMatcher(InvitationsAPI.endpoints.getWeddingInvitations.matchFulfilled, (state, action) => {
				const { invitations } = action.payload as { invitations: Invitations[] };
				state.landingList = invitations;
			})
			.addMatcher(InvitationsAPI.endpoints.getWeddingInvitation.matchFulfilled, (state, action) => {
				const { invitation } = action.payload as { invitation: Invitations };
				state.selectedInvitation = invitation;
			})
});

export const getFullInvitationsState = (state: RootState): invitationState => state.invitations;
export default InvitationsSlice.reducer;
