import React from "react";
import { useFormik } from "formik";
import { updatePasswordSchema } from "../../schemas";
import { toast } from "react-toastify";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { useUpdatePasswordMutation } from "../../../../store/services/auth/auth.service";
import { useNavigate } from "react-router-dom";
import { IError } from "../../../../utils/getErrorMessage";
import { notifyError } from "../../../../utils/NotifyError";

const UpdatePassword = ({ token }: { token: string }) => {
	const [callUpdatePassword, { isLoading }] = useUpdatePasswordMutation();
	const navigate = useNavigate();

	const {
		handleSubmit,
		handleChange,
		errors,
		values: { password, confirmPassword },
		dirty,
		isValid
	} = useFormik({
		validationSchema: updatePasswordSchema,
		initialValues: {
			password: "",
			confirmPassword: ""
		},
		onSubmit: values => {
			callUpdatePassword({ password: values.confirmPassword, token }).then(({ data, error }) => {
				if (data) {
					toast.success("Password updated successfully.");
					navigate("/login");
				}

				if (error) {
					notifyError(error as IError);
				}
			});
		}
	});

	return (
		<form onSubmit={handleSubmit} style={{ width: "100%" }}>
			<Box mb={2} className={"input_wrapper"}>
				<Typography variant={"subtitle1"} fontWeight={"bold"}>
					Password
				</Typography>
				<TextField
					fullWidth
					type={"password"}
					value={password}
					name={"password"}
					onChange={handleChange}
					error={!!errors.password}
					helperText={errors.password}
				/>
			</Box>
			<Box mb={2} className={"input_wrapper"}>
				<Typography variant={"subtitle1"} fontWeight={"bold"}>
					Confirm Password
				</Typography>
				<TextField
					fullWidth
					type={"password"}
					value={confirmPassword}
					name={"confirmPassword"}
					onChange={handleChange}
					error={!!errors.confirmPassword}
					helperText={errors.confirmPassword}
				/>
			</Box>
			<Box width={"100%"}>
				<Button
					fullWidth
					variant={"contained"}
					type="submit"
					disabled={(dirty && !isValid) || isLoading}
					startIcon={isLoading && <CircularProgress size={10} />}
				>
					Reset Password
				</Button>
			</Box>
		</form>
	);
};

export default UpdatePassword;
