import { createSlice } from "@reduxjs/toolkit";

interface landingState {}

const initialState: landingState = {};

const landingSlice = createSlice({
	name: "landing",
	initialState,
	reducers: {},
	extraReducers: builder => {}
});

// export const { increment, decrement, incrementByAmount } = landingSlice.actions;
export default landingSlice.reducer;
