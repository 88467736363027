export interface IError {
	data: { message: string; errors: string[] };
	error?: string;
}
export const getErrorMessage = (err: IError): string | string[] => {
	if (err.data) {
		return err.data.errors || err.data.message;
	}
	return err.error || "";
};
