import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { AuthContainer, AuthWrapper } from "../styles";
import { useSearchParams } from "react-router-dom";
import UpdatePassword from "./UpdatePassword";
import RequestReset from "./RequestReset";
const ResetPassword = () => {
	const [searchParams] = useSearchParams();
	const resetToken = searchParams.get("token");

	return (
		<Container>
			<AuthContainer>
				<AuthWrapper>
					{resetToken ? (
						<Box className={"left_block"}>
							<UpdatePassword token={resetToken} />
						</Box>
					) : (
						<Box className={"left_block"}>
							<RequestReset />
						</Box>
					)}
					<Box className={"right_block"}>
						<Typography variant={"h4"}>Reset Password</Typography>
						<Typography variant={"subtitle2"} textAlign={"center"}>
							You will receive instructions for resetting your password
						</Typography>
					</Box>
				</AuthWrapper>
			</AuthContainer>
		</Container>
	);
};

export default ResetPassword;
