import React from "react";
import { Box, Typography } from "@mui/material";
import NoData from "../../assets/imgs/search.svg";

const NoInvitation = () => {
	return (
		<Box
			className={"invitation-wrapper"}
			display={"flex"}
			justifyContent={"center"}
			alignItems={"center"}
			flexDirection={"column"}
		>
			<img src={NoData} alt="Invitation not found" width={250} />
			<Box mt={4}>
				<Typography variant={"h6"} fontWeight={"bold"}>
					No result found
				</Typography>
			</Box>
		</Box>
	);
};

export default NoInvitation;
