import React from "react";

import styled from "styled-components";
import { TextField } from "@mui/material";

const NumberInput = styled(TextField)`
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}
`;

const NumberInputField = ({ ...props }) => {
	return <NumberInput {...props} />;
};

export default NumberInputField;
