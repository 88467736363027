export enum AccountType {
	PRIMARY = 1,
	SOCIAL
}

export interface User {
	firstName: string;
	lastName: string;
	email: string;
	email_verified: boolean;
	email_verify_count: number;
	account_type: AccountType;
}

export interface ISignUp {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
}

export interface ILoginIn {
	email: string;
	password: string;
}
