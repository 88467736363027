import React from "react";

import { createBrowserRouter, Navigate } from "react-router-dom";
// pages
import Landing from "./Pages/Landing";
import Signup from "./Pages/Auth/Signup";
import Login from "./Pages/Auth/Login";
import VerifyEmail from "./Pages/Auth/VerifyEmail";
import ResetPassword from "./Pages/Auth/ResetPassword";
import NoRoute from "./Pages/NoRoute";
import WeddingInvitationTemplates from "./Pages/Templates/WeddingInvitationTemplates/WeddingInvitationTemplates";
import WeddingInvitation from "./Pages/Invitations/WeddingInvitations";
import MyAccount from "./Pages/MyAccount";
import Personal from "./Pages/MyAccount/Personal";
import MyInvitations from "./Pages/MyAccount/MyInvitations";
import Security from "./Pages/MyAccount/Security";

//components
import AppWrapper from "./Components/AppWrapper";
import AuthRoute from "./Components/AuthRoute";
import AppRoot from "./Components/AppRoot";
import VerificationCompleted from "./Pages/VerficationCompleted";
import ScrollToTop from "./Components/ScrollToTop";

export const router = createBrowserRouter([
	{
		element: (
			<AppRoot>
				<AppWrapper />
				<ScrollToTop />
			</AppRoot>
		),
		errorElement: <NoRoute />,
		children: [
			{
				path: "/",
				Component: Landing
			},
			{ path: "/signup", Component: Signup },
			{ path: "/login", Component: Login },
			{ path: "/resetPassword", Component: ResetPassword },
			{
				path: "/verifyEmail",
				Component: VerifyEmail
			},
			{
				path: "/verification/email",
				Component: VerificationCompleted
			},
			{
				path: "/weddingInvitation/:id",
				Component: WeddingInvitationTemplates
			},
			{
				element: <AuthRoute />,
				children: [
					{
						path: "/myAccount",
						Component: MyAccount,
						children: [
							{ index: true, element: <Navigate to="/myAccount/personal" replace /> },
							{
								path: "/myAccount/personal",
								Component: Personal
							},
							{
								path: "/myAccount/security",
								Component: Security
							},
							{
								path: "/myAccount/invitations",
								Component: MyInvitations
							}
						]
					},
					{
						path: "/manageWeddingInvitation/:id",
						Component: WeddingInvitation
					}
				]
			}
		]
	}
]);
