import React from "react";
import { useGetUserWeddingInvitationsQuery } from "../../../store/services/invitations/invitations.service";
import { Box, Button, Skeleton, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TemplateListWrapper } from "./styles";
import theme from "../../../theme";

const MyInvitations = () => {
	const { data, isLoading } = useGetUserWeddingInvitationsQuery({});
	const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));
	const navigate = useNavigate();

	if (isLoading) {
		const loadingArray = new Array(6).fill(0);
		return (
			<Box display="flex" style={{ flexFlow: "row wrap" }} gap={2}>
				{loadingArray.map((_, index) => (
					<Box key={index} width={isSmallerScreen ? "100%" : "250px"}>
						<Skeleton variant={"rectangular"} height={"300px"} />
						<Skeleton variant={"text"} sx={{ fontSize: "40px" }} />
						<Skeleton variant={"text"} />
					</Box>
				))}
			</Box>
		);
	}
	return (
		<TemplateListWrapper>
			{data?.invitations?.map(t => (
				<Box className={"template"} key={t._id} onClick={() => navigate(`/manageWeddingInvitation/${t._id}`)}>
					{t.is_published && <Box></Box>}

					<img src={t.thumbnail} alt="template" className={"thumbnail"} />
					<Box mt={1}>
						<Typography variant={"h6"}>{t.name}</Typography>
					</Box>

					<Box width={"100%"} mt={2}>
						<Button fullWidth variant={"outlined"} color={"primary"}>
							Manage
						</Button>
					</Box>
				</Box>
			))}
		</TemplateListWrapper>
	);
};

export default MyInvitations;
