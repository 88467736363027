import React, { useMemo } from "react";
import { useAppSelector } from "../../store";
import { getFullState } from "../../store/slices/auth/auth.slice";
import { Alert, Button, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { notifyError } from "../../utils/NotifyError";
import { IError } from "../../utils/getErrorMessage";
import { useResendVerifyEmailMutation } from "../../store/services/auth/auth.service";
import { format } from "date-fns";

const EMAIL_VERIFY_LIMIT = 3;
const MAINTENANCE_MODE = JSON.parse(process.env.REACT_APP_MAINTENANCE_MODE || "{}");

const Banner = (): React.ReactElement | null => {
	const { isLoggedIn, user } = useAppSelector(getFullState);
	const [callResendVerification, { isLoading: isVerifying }] = useResendVerifyEmailMutation();

	const hasReachedVerificationLimit = useMemo(() => user && user.email_verify_count === EMAIL_VERIFY_LIMIT, [user]);

	const handleResendEmail = () => {
		callResendVerification({}).then(({ data, error }) => {
			if (data) {
				toast.success("Verification email was sent successfully", { autoClose: 3000 });
			}
			if (error) {
				notifyError(error as IError);
			}
		});
	};

	if (isLoggedIn && user && !user.email_verified) {
		return (
			<Alert
				style={{ position: "relative", zIndex: 2 }}
				severity="warning"
				action={
					<Button
						variant={"contained"}
						color={"primary"}
						size="small"
						onClick={handleResendEmail}
						disabled={hasReachedVerificationLimit || isVerifying}
						startIcon={isVerifying && <CircularProgress size={10} />}
					>
						Resend Email
					</Button>
				}
			>
				{hasReachedVerificationLimit
					? "Limit reached for resending verification email, please contact support"
					: "Unverified Account, Please check your email inbox for a verification link to activate your account"}
			</Alert>
		);
	}
	if (MAINTENANCE_MODE.isActive) {
		return (
			<Alert severity="info" style={{ position: "relative", zIndex: 2 }}>
				Our website is currently undergoing scheduled maintenance to improve your experience. We expect to be back
				online by {format(MAINTENANCE_MODE.overBy, "LLL d, yyy, hh:mm aa")}
			</Alert>
		);
	}
	return null;
};

export default Banner;
