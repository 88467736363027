import styled from "styled-components";

import { Box } from "@mui/material";

export const WeddingInvitationContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;

	.invitation-wrapper {
		width: 450px;
		min-height: 94vh;
	}
	.invitation-buy-button {
		width: 450px;
		position: fixed;
		bottom: 0;
	}

	${props => props.theme.breakpoints.down("md")} {
		.invitation-wrapper {
			width: 100%;
		}
		.invitation-buy-button {
			width: 100%;
		}
	}
`;
