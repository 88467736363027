import React from "react";
import { Box, Skeleton, Stack } from "@mui/material";

const InvitationLoader = () => {
	return (
		<Box className={"invitation-wrapper"}>
			<Stack spacing={1}>
				<Skeleton variant={"rectangular"} height={"40vh"} />
				<Skeleton variant={"rectangular"} height={"20vh"} />
				<Skeleton variant={"rectangular"} height={"30vh"} />
			</Stack>
		</Box>
	);
};

export default InvitationLoader;
