import React from "react";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { resetPasswordSchema } from "../../schemas";
import { toast } from "react-toastify";
import { useResetPasswordMutation } from "../../../../store/services/auth/auth.service";
import { IError } from "../../../../utils/getErrorMessage";
import { notifyError } from "../../../../utils/NotifyError";

const RequestReset = () => {
	const [callResetPassword, { isLoading }] = useResetPasswordMutation();

	const {
		handleSubmit,
		handleChange,
		errors,
		values: { email },
		dirty,
		isValid
	} = useFormik({
		validationSchema: resetPasswordSchema,
		initialValues: {
			email: ""
		},
		onSubmit: values => {
			callResetPassword(values).then(({ data, error }) => {
				if (data) {
					toast.success("We will send you a notification if your email exists!");
				}
				if (error) {
					notifyError(error as IError);
				}
			});
		}
	});
	return (
		<form onSubmit={handleSubmit} style={{ width: "100%" }}>
			<Typography variant={"h6"} className={"title"}>
				Reset Password
			</Typography>
			<Box mt={2} width={"100%"}>
				<Box mb={2} className={"input_wrapper"}>
					<Typography variant={"subtitle1"} fontWeight={"bold"}>
						Email
					</Typography>
					<TextField
						fullWidth
						type={"email"}
						value={email}
						name={"email"}
						onChange={handleChange}
						error={!!errors.email}
						helperText={errors.email}
					/>
				</Box>
			</Box>
			<Box width={"100%"}>
				<Button
					fullWidth
					variant={"contained"}
					type="submit"
					disabled={(dirty && !isValid) || isLoading}
					startIcon={isLoading && <CircularProgress size={10} />}
				>
					Reset Password
				</Button>
			</Box>
		</form>
	);
};

export default RequestReset;
