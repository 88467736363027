import { createSlice } from "@reduxjs/toolkit";
import { authAPI } from "../../services/auth/auth.service";
import { User } from "../../services/auth/dto/auth.dto";
import { RootState } from "../../index";

interface authState {
	user: User | null;
	isLoading: boolean;
	isLoggedIn: boolean;
}

const initialState: authState = {
	user: null,
	isLoading: false,
	isLoggedIn: false
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		logOut: state => {
			state.user = null;
			state.isLoggedIn = false;
		}
	},
	extraReducers: builder =>
		builder
			.addMatcher(authAPI.endpoints.signUp.matchPending, state => {
				state.isLoading = true;
			})
			.addMatcher(authAPI.endpoints.signUp.matchFulfilled, (state, action) => {
				const { user, token } = action.payload as { user: User; token: string };
				state.user = user;
				state.isLoggedIn = true;
				localStorage.setItem("token", token);
				state.isLoading = false;
			})
			.addMatcher(authAPI.endpoints.signUp.matchRejected, state => {
				state.isLoading = false;
				state.isLoggedIn = false;
			})
			.addMatcher(authAPI.endpoints.login.matchPending, state => {
				state.isLoading = true;
			})
			.addMatcher(authAPI.endpoints.login.matchFulfilled, (state, action) => {
				const { user, token } = action.payload as { user: User; token: string };
				state.user = user;
				state.isLoggedIn = true;
				localStorage.setItem("token", token);
				state.isLoading = false;
			})
			.addMatcher(authAPI.endpoints.login.matchRejected, state => {
				state.isLoading = false;
				state.isLoggedIn = false;
			})
			.addMatcher(authAPI.endpoints.fetchUserData.matchPending, state => {
				state.isLoading = true;
			})
			.addMatcher(authAPI.endpoints.fetchUserData.matchFulfilled, (state, action) => {
				const { user, token } = action.payload as { user: User; token: string };
				state.user = user;
				state.isLoggedIn = true;
				localStorage.setItem("token", token);
				state.isLoading = false;
			})
			.addMatcher(authAPI.endpoints.fetchUserData.matchRejected, state => {
				state.isLoading = false;
				state.isLoggedIn = false;
			})
			.addMatcher(authAPI.endpoints.verifyEmail.matchFulfilled, state => {
				if (state.user) state.user.email_verified = true;
			})
			.addMatcher(authAPI.endpoints.loginWithGoogle.matchFulfilled, (state, action) => {
				const { user, token } = action.payload as { user: User; token: string };
				state.user = user;
				state.isLoggedIn = true;
				localStorage.setItem("token", token);
				state.isLoading = false;
			})
});
export const { logOut } = authSlice.actions;
export const getFullState = (state: RootState): authState => state.auth;
export const getIsLoggedIn = (state: RootState): authState => state.auth.isLoggedIn;
export default authSlice.reducer;
