import React from "react";
import {
	Box,
	Button,
	Avatar,
	colors,
	CircularProgress,
	Typography,
	TextField,
	Divider,
	useMediaQuery
} from "@mui/material";
import { useAppSelector } from "../../../store";
import { getFullState } from "../../../store/slices/auth/auth.slice";
import { useFormik } from "formik";
import { myAccountSchema } from "./schema";
import theme from "../../../theme";
import { useLazyFetchUserDataQuery, useUpdateUserAccountMutation } from "../../../store/services/auth/auth.service";
import { toast } from "react-toastify";
import { notifyError } from "../../../utils/NotifyError";
import { IError } from "../../../utils/getErrorMessage";

const Personal = () => {
	const { user } = useAppSelector(getFullState);
	const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));
	const [callUpdateAccount, { isLoading: isSaving }] = useUpdateUserAccountMutation();
	const [callWhoami] = useLazyFetchUserDataQuery();
	const {
		values: { firstName, lastName, email },
		handleChange,
		errors,
		handleSubmit
	} = useFormik({
		initialValues: {
			firstName: user?.firstName || "",
			lastName: user?.lastName || "",
			email: user?.email || ""
		},
		validationSchema: myAccountSchema,
		onSubmit: values => {
			callUpdateAccount({ body: values }).then(({ data, error }) => {
				if (data) {
					toast.success("Account has been updated successfully", { autoClose: 3000 });
					callWhoami();
				}
				if (error) {
					notifyError(error as IError);
				}
			});
		}
	});

	if (!user) {
		return <CircularProgress />;
	}
	return (
		<Box display="flex" flexDirection="column">
			<Box display="flex" alignItems={"center"}>
				<Avatar
					sx={{
						bgcolor: colors.deepOrange[500],
						fontSize: isSmallerScreen ? "40px" : "60px",
						fontWeight: "bold",
						width: isSmallerScreen ? 70 : 120,
						height: isSmallerScreen ? 70 : 120
					}}
				>
					{user.firstName[0]}
				</Avatar>
				<Box ml={2}>
					<Typography variant={isSmallerScreen ? "h6" : "h4"} fontWeight={"bold"}>
						{user.firstName + " " + user.lastName}
					</Typography>
					<Typography variant={"subtitle1"} color={"textSecondary"}>
						{user.email}
					</Typography>
				</Box>
			</Box>
			<Box my={3}>
				<Divider />
			</Box>
			<form onSubmit={handleSubmit}>
				<Box mt={2} display={"flex"} flexDirection="column" sx={{ width: isSmallerScreen ? "100%" : "50%" }}>
					<Box display={"flex"} mb={2}>
						<Box mr={3} width={"100%"}>
							<Typography variant={"subtitle1"} fontWeight={"bold"}>
								First Name
							</Typography>
							<TextField
								name={"firstName"}
								onChange={handleChange}
								error={!!errors.firstName}
								helperText={errors.firstName}
								fullWidth
								value={firstName}
							/>
						</Box>
						<Box width={"100%"}>
							<Typography variant={"subtitle1"} fontWeight={"bold"}>
								Last Name
							</Typography>
							<TextField
								name={"lastName"}
								onChange={handleChange}
								error={!!errors.lastName}
								helperText={errors.lastName}
								fullWidth
								value={lastName}
							/>
						</Box>
					</Box>
					<Box mb={2}>
						<Typography variant={"subtitle1"} fontWeight={"bold"}>
							Email
						</Typography>
						<TextField
							type={"email"}
							name={"email"}
							onChange={handleChange}
							error={!!errors.email}
							helperText={errors.email}
							fullWidth
							value={email}
						/>
					</Box>
					<Box mt={4}>
						<Button
							fullWidth
							variant={"contained"}
							color={"primary"}
							type="submit"
							disabled={isSaving}
							startIcon={isSaving && <CircularProgress size={10} />}
						>
							Save
						</Button>
					</Box>
				</Box>
			</form>
		</Box>
	);
};

export default Personal;
