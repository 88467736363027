import { getErrorMessage, IError } from "./getErrorMessage";
import { toast } from "react-toastify";

export const notifyError = (error: IError, closeTime?: number) => {
	const err = getErrorMessage(error);
	if (typeof err === "string") {
		toast.error(err, { autoClose: closeTime || 3000 });
	}
	if (typeof err === "object") {
		err.forEach(e => toast.error(e));
	}
};
