import styled from "styled-components";

export const TemplateListWrapper = styled.div`
	display: flex;
	flex-flow: row wrap;
	.template {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin: 15px;
		position: relative;
		width: 250px;
		cursor: pointer;

		.thumbnail {
			width: inherit;
			height: 450px;
		}
	}

	${props => props.theme.breakpoints.down("sm")} {
		justify-content: center;

		.template {
			width: 95%;
			margin: 15px 0;
			.thumbnail {
				width: 100%;
				height: 500px;
			}
			.coming_soon_over_lay {
				width: 100%;
				height: 500px;
			}
		}
	}
`;
