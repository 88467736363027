import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { NoRouteWrapper } from "./styles";
import NotFound from "../../assets/imgs/not_found.svg";
import { useNavigate } from "react-router-dom";

const NoRoute = () => {
	const navigate = useNavigate();
	return (
		<NoRouteWrapper>
			<img src={NotFound} alt="404 not found" width={250} />
			<Box
				mt={6}
				display={"flex"}
				flexDirection={"column"}
				alignItems={"center"}
				justifyContent={"center"}
				gap={1}
				p={2}
			>
				<Typography variant={"h4"}>There's NOTHING here!</Typography>
				<Typography variant={"subtitle1"} textAlign={"center"}>
					you are looking for a page that is not found or never existed
				</Typography>
				<Button variant={"contained"} color={"primary"} onClick={() => navigate("/")}>
					Go Home
				</Button>
			</Box>
		</NoRouteWrapper>
	);
};

export default NoRoute;
