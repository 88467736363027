import styled from "styled-components";
import { Box } from "@mui/material";

export const Wrapper = styled(Box)`
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	h4,
	h6 {
		font-family: var(--openSans);
		font-weight: bold;
		text-align: center;
	}
	button {
		margin-top: 20px;
		padding: 8px 40px;
	}
	img {
		width: 350px;
	}
	${props => props.theme.breakpoints.down("md")} {
		img {
			width: 250px;
		}
		h4 {
			font-size: 24px;
		}
		h6 {
			font-size: 18px;
		}
	}
`;
