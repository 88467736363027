import styled from "styled-components";
import { Box } from "@mui/material";

export const FallbackWrapper = styled(Box)`
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	h3,
	h5 {
		font-family: var(--openSans);
		font-weight: bold;
	}
`;
