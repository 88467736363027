import React from "react";
import {
	Box,
	Container,
	Typography,
	Grid2,
	IconButton,
	List,
	MenuItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	colors,
	useMediaQuery
} from "@mui/material";
import { ArrowBack, Person, Email, Lock } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import theme from "../../theme";

const MyAccount = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const isPersonalSelected = location.pathname.includes("/personal");
	const isInvitationSelected = location.pathname.includes("/invitations");
	const isSecuritySelected = location.pathname.includes("/security");
	const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));
	const handleGoBack = () => {
		navigate("/");
	};

	return (
		<Container maxWidth={"xl"}>
			<Box mt={7} height={"94vh"} p={1}>
				<Box display={"flex"} alignItems={"center"} gap={1} my={4} style={{ cursor: "pointer" }} onClick={handleGoBack}>
					<IconButton>
						<ArrowBack width={20} />
					</IconButton>
					<Typography> MyAccount</Typography>
				</Box>
				<Grid2 container spacing={4} alignItems={"flex-start"}>
					<Grid2 size={isSmallerScreen ? 12 : 3} style={{ backgroundColor: colors.grey[100] }}>
						<List style={isSmallerScreen ? { display: "flex", justifyContent: "center" } : {}}>
							<MenuItem selected={isPersonalSelected}>
								<ListItemButton onClick={() => navigate("/myAccount/personal")}>
									<ListItemIcon style={isSmallerScreen ? { display: "flex", justifyContent: "center" } : {}}>
										<Person />
									</ListItemIcon>
									{!isSmallerScreen && <ListItemText primary="Personal" />}
								</ListItemButton>
							</MenuItem>
							<MenuItem selected={isSecuritySelected}>
								<ListItemButton onClick={() => navigate("/myAccount/security")}>
									<ListItemIcon style={isSmallerScreen ? { display: "flex", justifyContent: "center" } : {}}>
										<Lock />
									</ListItemIcon>
									{!isSmallerScreen && <ListItemText primary="Security" />}
								</ListItemButton>
							</MenuItem>
							<MenuItem selected={isInvitationSelected}>
								<ListItemButton onClick={() => navigate("/myAccount/invitations")}>
									<ListItemIcon style={isSmallerScreen ? { display: "flex", justifyContent: "center" } : {}}>
										<Email />
									</ListItemIcon>
									{!isSmallerScreen && <ListItemText primary="Invitations" />}
								</ListItemButton>
							</MenuItem>
						</List>
					</Grid2>
					<Grid2 size={isSmallerScreen ? 12 : 9}>
						<Outlet />
					</Grid2>
				</Grid2>
			</Box>
		</Container>
	);
};

export default MyAccount;
