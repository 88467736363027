import { api } from "../index";
import { ILoginIn, ISignUp, User } from "./dto/auth.dto";

const BASE_AUTH_ENDPOINT = "/auth";
const BASE_USER_ENDPOINT = "/users";

export const authAPI = api.injectEndpoints({
	endpoints: build => ({
		signUp: build.mutation<{ user: User; token: string }, ISignUp>({
			query: body => ({
				url: `${BASE_AUTH_ENDPOINT}/signup`,
				method: "POST",
				body
			})
		}) as any,
		login: build.mutation<{ user: User; token: string }, ILoginIn>({
			query: body => ({
				url: `${BASE_AUTH_ENDPOINT}/login`,
				method: "POST",
				body
			})
		}) as any,
		fetchUserData: build.query<{ user: User; token: string }, void>({
			query: () => ({ url: `${BASE_USER_ENDPOINT}/whoami`, method: "GET" })
		}),
		verifyEmail: build.mutation<{ token: string }, { success: boolean }>({
			query: body => ({
				url: `${BASE_AUTH_ENDPOINT}/verifyEmail`,
				method: "PUT",
				body
			})
		}) as any,
		resendVerifyEmail: build.mutation<{ success: boolean }, void>({
			query: body => ({
				url: `${BASE_USER_ENDPOINT}/resendVerificationEmail`,
				method: "PUT",
				body
			})
		}) as any,
		resetPassword: build.mutation<{ success: boolean }, { email: string }>({
			query: body => ({
				url: `${BASE_AUTH_ENDPOINT}/resetPassword`,
				method: "POST",
				body
			})
		}) as any,
		updatePassword: build.mutation<{ success: boolean }, { password: string; token: string }>({
			query: body => ({
				url: `${BASE_AUTH_ENDPOINT}/updatePassword`,
				method: "PUT",
				body
			})
		}) as any,
		updateAccountPassword: build.mutation<{ success: boolean }, { password: string; oldPassword: string }>({
			query: body => ({
				url: `${BASE_USER_ENDPOINT}/updatePassword`,
				method: "PUT",
				body
			})
		}) as any,
		loginWithGoogle: build.query<{ user: User; token: string }, { token: string }>({
			query: body => ({
				url: `${BASE_AUTH_ENDPOINT}/loginWithGoogle`,
				method: "POST",
				body
			})
		}),
		updateUserAccount: build.mutation<{ success: boolean }, { body: Pick<User, "firstName" | "lastName" | "email"> }>({
			query: ({ body }) => ({
				url: `${BASE_USER_ENDPOINT}`,
				method: "PUT",
				body
			})
		})
	})
});

export const {
	useSignUpMutation,
	useLoginMutation,
	useLazyFetchUserDataQuery,
	useVerifyEmailMutation,
	useResendVerifyEmailMutation,
	useResetPasswordMutation,
	useUpdatePasswordMutation,
	useLazyLoginWithGoogleQuery,
	useUpdateUserAccountMutation,
	useUpdateAccountPasswordMutation
} = authAPI;
