import { fetchBaseQuery, createApi, FetchArgs, BaseQueryApi } from "@reduxjs/toolkit/query/react";

// import { RootState } from "../index";

export const baseEndPoint = process.env.REACT_APP_BACKEND_API;
export const baseQuery = fetchBaseQuery({
	baseUrl: baseEndPoint,
	prepareHeaders: (headers, { getState }) => {
		// By default, if we have a token in the store, let's use that for authenticated requests
		const token = localStorage.getItem("token");
		if (token) {
			headers.set("authentication", `Bearer ${token}`);
		}
		return headers;
	}
});

const baseQueryWithAuth = async (args: FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
	const result = await baseQuery(args, api, extraOptions);
	if (result.error && result.error.status === 401) {
		// Handle the 401 error here, e.g., redirecting to login
		localStorage.clear();
		window.location.href = "/login";
	}
	return result;
};

export const api = createApi({
	reducerPath: "api",
	keepUnusedDataFor: 30,
	baseQuery: baseQueryWithAuth,
	tagTypes: ["Invitations"],
	endpoints: () => ({})
});
