import React from "react";
import { Box, Typography } from "@mui/material";
import bugFixing from "../../assets/imgs/bugFixing.svg";
import { FallbackWrapper } from "./styles";

const ErrorFallback = () => {
	return (
		<FallbackWrapper>
			<img src={bugFixing} alt="Bug fixing image" width={350} />
			<Box mt={2} display={"flex"} flexDirection={"column"} alignItems={"center"} gap={1} p={2}>
				<Typography variant={"h3"}>Woops!</Typography>
				<Typography variant={"h5"}>Something went wrong :(</Typography>
				<Typography variant={"subtitle1"} textAlign={"center"}>
					We're sorry, but an unexpected error occurred. Our team has been automatically notified, <br /> and we're
					already working on resolving the issue.
				</Typography>
			</Box>
		</FallbackWrapper>
	);
};

export default ErrorFallback;
