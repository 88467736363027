import React from "react";

import { Box, Button, Typography } from "@mui/material";
import { Wrapper } from "./styles";
import Done from "../../assets/imgs/Done.svg";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store";
import { getIsLoggedIn } from "../../store/slices/auth/auth.slice";

const VerificationCompleted = () => {
	const navigate = useNavigate();
	const isLoggedIn = useAppSelector(getIsLoggedIn);
	return (
		<Wrapper>
			<img src={Done} alt="404 not found" width={250} />
			<Box
				mt={6}
				display={"flex"}
				flexDirection={"column"}
				alignItems={"center"}
				justifyContent={"center"}
				gap={1}
				p={2}
			>
				<Typography variant={"h4"}>Verified Successfully!</Typography>
				<Typography variant={"subtitle1"} textAlign={"center"}>
					your email has been verified.
				</Typography>
				<Button variant={"contained"} color={"primary"} onClick={() => navigate(isLoggedIn ? "/" : "/login")}>
					{isLoggedIn ? "Go Home" : "Login"}
				</Button>
			</Box>
		</Wrapper>
	);
};

export default VerificationCompleted;
