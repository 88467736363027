import React from "react";
import { Box, Button, Container, TextField, Typography, CircularProgress, Divider } from "@mui/material";
import { AuthContainer, AuthWrapper } from "../styles";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { signupSchema } from "../schemas";
import { useLazyLoginWithGoogleQuery, useSignUpMutation } from "../../../store/services/auth/auth.service";
import { omit } from "lodash";
import { IError } from "../../../utils/getErrorMessage";
import { notifyError } from "../../../utils/NotifyError";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";

const Signup = () => {
	const navigate = useNavigate();
	const [callSignup, { isLoading }] = useSignUpMutation();
	const [callLoginWithGoogle] = useLazyLoginWithGoogleQuery();

	const {
		handleSubmit,
		handleChange,
		errors,
		values: { firstName, lastName, email, password, confirmPassword },
		dirty,
		isValid
	} = useFormik({
		validationSchema: signupSchema,
		initialValues: {
			firstName: "",
			lastName: "",
			email: "",
			password: "",
			confirmPassword: ""
		},
		onSubmit: values => {
			const _values = omit(values, ["confirmPassword"]);
			callSignup(_values).then(({ data, error }) => {
				if (data) {
					navigate("/");
				}
				const _error: { data: { message: string }; error?: string } = error as {
					data: { message: string };
					error?: string;
				};
				if (_error) {
					notifyError(error as IError);
				}
			});
		}
	});

	const responseMessage = (response: CredentialResponse) => {
		callLoginWithGoogle({ token: response.credential as string }).then(({ data, error }) => {
			if (data) {
				navigate("/");
			}
			if (error) {
				notifyError(error as IError);
			}
		});
	};

	const handleError = () => {
		console.log("error");
	};
	return (
		<Container>
			<AuthContainer>
				<form onSubmit={handleSubmit}>
					<AuthWrapper>
						<Box className={"left_block"}>
							<Typography variant={"h6"} className={"title"}>
								Sign Up
							</Typography>
							<Box mt={2} width={"100%"}>
								<Box mb={2} display={"flex"} justifyContent={"space-between"}>
									<Box mr={1} className={"input_wrapper"} flexGrow={1}>
										<Typography variant={"subtitle1"} fontWeight={"bold"}>
											First Name
										</Typography>
										<TextField
											fullWidth
											type={"text"}
											value={firstName}
											name={"firstName"}
											onChange={handleChange}
											error={!!errors.firstName}
											helperText={errors.firstName}
											autoFocus={true}
										/>
									</Box>
									<Box className={"input_wrapper"} flexGrow={1}>
										<Typography variant={"subtitle1"} fontWeight={"bold"}>
											Last Name
										</Typography>
										<TextField
											fullWidth
											type={"text"}
											value={lastName}
											name={"lastName"}
											onChange={handleChange}
											error={!!errors.lastName}
											helperText={errors.lastName}
										/>
									</Box>
								</Box>
								<Box mb={2} className={"input_wrapper"}>
									<Typography variant={"subtitle1"} fontWeight={"bold"}>
										Email
									</Typography>
									<TextField
										fullWidth
										type={"email"}
										value={email}
										name={"email"}
										onChange={handleChange}
										error={!!errors.email}
										helperText={errors.email}
										autoComplete={"email"}
									/>
								</Box>
								<Box mb={2} className={"input_wrapper"}>
									<Typography variant={"subtitle1"} fontWeight={"bold"}>
										Password
									</Typography>
									<TextField
										fullWidth
										type={"password"}
										value={password}
										name={"password"}
										onChange={handleChange}
										error={!!errors.password}
										helperText={errors.password}
									/>
								</Box>
								<Box mb={2} className={"input_wrapper"}>
									<Typography variant={"subtitle1"} fontWeight={"bold"}>
										Confirm Password
									</Typography>
									<TextField
										fullWidth
										type={"password"}
										value={confirmPassword}
										name={"confirmPassword"}
										onChange={handleChange}
										error={!!errors.confirmPassword}
										helperText={errors.confirmPassword}
									/>
								</Box>
							</Box>
							<Box width={"100%"}>
								<Button
									fullWidth
									variant={"contained"}
									type={"submit"}
									disabled={(dirty && !isValid) || isLoading}
									startIcon={isLoading && <CircularProgress size={10} />}
								>
									Sign Up
								</Button>
							</Box>
							<Box width={"100%"} my={2}>
								<Divider />
							</Box>

							<Box
								display={"flex"}
								justifyContent={"center"}
								width={"100%"}
								py={1}
								flexDirection={"column"}
								alignItems={"center"}
								gap={3}
							>
								<Typography variant={"subtitle1"}>or Sign Up with</Typography>
								<GoogleLogin onSuccess={responseMessage} onError={handleError} />
							</Box>
						</Box>
						<Box className={"right_block"}>
							<Typography variant={"h4"}>Welcome to Sign Up</Typography>
							<Typography variant={"subtitle2"}>Already a user?</Typography>
							<Button variant={"contained"} size={"large"} onClick={() => navigate("/login")}>
								Login
							</Button>
						</Box>
					</AuthWrapper>
				</form>
			</AuthContainer>
		</Container>
	);
};

export default Signup;
