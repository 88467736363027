import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import Locked from "../../assets/imgs/secured.svg";
import NumberInputField from "../NumberInputField";
import { useLocation, useNavigate } from "react-router-dom";

const LockedInvitation = ({ errorMessage }: { errorMessage: string }) => {
	const passCodeRef = useRef<HTMLInputElement[]>([]);
	const [passCode, setPassCode] = useState<(number | undefined)[]>(new Array(6).fill(""));
	const location = useLocation();
	const navigate = useNavigate();
	const isWrong = errorMessage.includes("Wrong");

	useEffect(() => {
		const isValid = passCode.every(p => Number(p));
		if (isValid) {
			navigate(`${location.pathname}?password=${passCode.join("")}`);
		}
	}, [passCode]);

	return (
		<Box
			className={"invitation-wrapper"}
			display={"flex"}
			justifyContent={"center"}
			alignItems={"center"}
			flexDirection={"column"}
		>
			<img src={Locked} alt="Invitation locked" width={250} />
			<Box mt={4} display={"flex"} flexDirection={"column"} alignItems={"center"}>
				<Typography variant={"h6"} fontWeight={"bold"}>
					{isWrong ? "Wrong password" : "Invitation is locked"}
				</Typography>
				<Typography variant={"subtitle2"} color={"textSecondary"}>
					Please enter passcode to get invitation access
				</Typography>
			</Box>
			<Box mt={2} display={"flex"} gap={1}>
				{passCode.map((p, i) => (
					<NumberInputField
						key={i}
						value={p}
						id={`pass-${i}`}
						inputRef={(element: HTMLInputElement) => (passCodeRef.current[i] = element)}
						inputProps={{
							maxLength: 1,
							inputMode: "numeric"
						}}
						type={"number"}
						variant="outlined"
						style={{ width: "40px" }}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							const value = e.target.value;
							if (value.length > 1 || !/^[0-9]?$/.test(value)) {
								return e.preventDefault();
							}
							setPassCode(prevState => {
								const list = [...prevState];
								list[i] = +e.target.value;
								return list;
							});
							if (i < passCode.length - 1) {
								passCodeRef.current[i + 1].focus();
							}
						}}
					/>
				))}
			</Box>
		</Box>
	);
};

export default LockedInvitation;
