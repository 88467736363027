import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../Header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLazyFetchUserDataQuery } from "../../store/services/auth/auth.service";
import { useAppDispatch, useAppSelector } from "../../store";
import { getFullState, logOut } from "../../store/slices/auth/auth.slice";
import Banner from "../Banner";
import { api } from "../../store/services";

const AppWrapper = () => {
	const localStorageToken = localStorage.getItem("token");
	const [callWhoami] = useLazyFetchUserDataQuery();

	const [loader, setLoader] = useState(true);
	const { isLoggedIn } = useAppSelector(getFullState);
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (isLoggedIn) {
			switch (location.pathname) {
				case "/login": {
					return navigate("/");
				}
				case "/signup": {
					return navigate("/");
				}
				default: {
				}
			}
		}
	}, [isLoggedIn, location.pathname]);

	useEffect(() => {
		if (!isLoggedIn && localStorageToken) {
			callWhoami().then(() => {
				setLoader(false);
			});
		} else {
			setLoader(false);
		}
		//  logout user in case not token in local storage
		if (!localStorageToken) {
			dispatch(logOut());
			dispatch(api.util.resetApiState());
		}
	}, [isLoggedIn, localStorageToken]);

	return (
		<>
			{loader ? (
				<Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"100vh"}>
					<CircularProgress />
				</Box>
			) : (
				<Box>
					<Banner />
					<Box position={"relative"}>
						{!location.pathname.includes("/weddingInvitation/") && <Header />}
						<Outlet />
					</Box>
				</Box>
			)}
			<ToastContainer position="bottom-right" hideProgressBar={true} theme="colored" />
		</>
	);
};

export default AppWrapper;
