import React, { useEffect, useRef } from "react";
import { Box, Container, CircularProgress, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useVerifyEmailMutation } from "../../../store/services/auth/auth.service";
import { toast } from "react-toastify";
import { IError } from "../../../utils/getErrorMessage";
import { notifyError } from "../../../utils/NotifyError";

const VerifyEmail = () => {
	const [searchParams] = useSearchParams();
	const verification_token = searchParams.get("token");
	const [callVerifyEmail] = useVerifyEmailMutation();
	const navigate = useNavigate();
	const initialized = useRef(false);

	useEffect(() => {
		if (!initialized.current) {
			initialized.current = true;
			callVerifyEmail({ token: verification_token }).then(({ data, error }) => {
				if (error) {
					notifyError(error as IError);
					navigate("/");
				}
				if (data) {
					toast.success("Account was verified successfully", { autoClose: 3000 });
					navigate("/verification/email");
				}
			});
		}
	}, []);

	return (
		<Container>
			<Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} height={"90vh"}>
				<Typography variant={"h6"} sx={{ marginBottom: "40px", fontWeight: "bold" }}>
					Verifying your email
				</Typography>
				<CircularProgress size={60} />
			</Box>
		</Container>
	);
};

export default VerifyEmail;
