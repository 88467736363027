import React from "react";
import { Box, Button, Container, Divider, Typography, TextField, CircularProgress } from "@mui/material";
import { AuthContainer, AuthWrapper } from "../styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";

import { loginSchema } from "../schemas";
import { useLazyLoginWithGoogleQuery, useLoginMutation } from "../../../store/services/auth/auth.service";
import { IError } from "../../../utils/getErrorMessage";
import { notifyError } from "../../../utils/NotifyError";

import { CredentialResponse, GoogleLogin } from "@react-oauth/google";

const Login = () => {
	const navigate = useNavigate();
	const [callLogin, { isLoading }] = useLoginMutation();
	const [callLoginWithGoogle] = useLazyLoginWithGoogleQuery();
	const [searchParams] = useSearchParams();
	const redirectUrl = searchParams.get("redirect");

	const {
		handleSubmit,
		handleChange,
		errors,
		values: { email, password },
		dirty,
		isValid
	} = useFormik({
		validationSchema: loginSchema,
		initialValues: {
			email: "",
			password: ""
		},
		onSubmit: values => {
			callLogin(values).then(({ data, error }) => {
				if (data) {
					navigate(redirectUrl ? redirectUrl : "/");
				}
				if (error) {
					notifyError(error as IError);
				}
			});
		}
	});

	const responseMessage = (response: CredentialResponse) => {
		callLoginWithGoogle({ token: response.credential as string }).then(({ data, error }) => {
			if (data) {
				navigate("/");
			}
			if (error) {
				notifyError(error as IError);
			}
		});
	};

	const handleError = () => {
		console.log("error");
	};

	return (
		<Container>
			<AuthContainer>
				<form onSubmit={handleSubmit}>
					<AuthWrapper>
						<Box className={"left_block"}>
							<Typography variant={"h6"} className={"title"}>
								Sign In
							</Typography>
							<Box mt={2} width={"100%"}>
								<Box mb={2} className={"input_wrapper"}>
									<Typography variant={"subtitle1"} fontWeight={"bold"}>
										Email
									</Typography>
									<TextField
										fullWidth
										type={"email"}
										value={email}
										name={"email"}
										onChange={handleChange}
										error={!!errors.email}
										helperText={errors.email}
										autoComplete={"email"}
									/>
								</Box>
								<Box mb={2} className={"input_wrapper"}>
									<Typography variant={"subtitle1"} fontWeight={"bold"}>
										Password
									</Typography>
									<TextField
										fullWidth
										type={"password"}
										value={password}
										name={"password"}
										onChange={handleChange}
										error={!!errors.password}
										helperText={errors.password}
										autoComplete={"password"}
									/>
								</Box>
							</Box>
							<Box width={"100%"}>
								<Button
									fullWidth
									variant={"contained"}
									type="submit"
									disabled={(dirty && !isValid) || isLoading}
									startIcon={isLoading && <CircularProgress size={10} />}
								>
									Sign In
								</Button>
							</Box>
							<Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
								<Button variant={"text"} onClick={() => navigate("/resetPassword")}>
									Forgot Password
								</Button>
							</Box>
							<Box width={"100%"} my={2}>
								<Divider />
							</Box>

							<Box
								display={"flex"}
								justifyContent={"center"}
								width={"100%"}
								py={1}
								flexDirection={"column"}
								alignItems={"center"}
								gap={3}
							>
								<Typography variant={"subtitle1"}>or Login with</Typography>
								<GoogleLogin onSuccess={responseMessage} onError={handleError} />
							</Box>
						</Box>

						<Box className={"right_block"}>
							<Typography variant={"h4"}>Welcome to login</Typography>
							<Typography variant={"subtitle2"}>Don't have an account?</Typography>
							<Button variant={"contained"} size={"large"} onClick={() => navigate("/signup")}>
								Sign Up
							</Button>
						</Box>
					</AuthWrapper>
				</form>
			</AuthContainer>
		</Container>
	);
};

export default Login;
