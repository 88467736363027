import styled, { css } from "styled-components";
import { PersonOutline } from "@mui/icons-material";
import { Box } from "@mui/material";

export const HeaderWrapper = styled(Box)<{ $isLandingPage: boolean }>`
	width: 100%;
	position: ${({ $isLandingPage }) => ($isLandingPage ? "absolute" : "fixed")};
	top: 0;
	z-index: 1;
	${({ theme, $isLandingPage }) =>
		!$isLandingPage
			? css`
					background: ${theme.custom_palette.color1};
				`
			: null}
`;

export const StyledPerson = styled(PersonOutline)`
	path {
		fill: ${({ theme }) => theme.palette.text.primary};
	}
`;
