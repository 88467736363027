import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAppSelector } from "../../store";
import { getIsLoggedIn } from "../../store/slices/auth/auth.slice";

const AuthRoute = () => {
	const isLoggedIn = useAppSelector(getIsLoggedIn);
	const location = useLocation();

	return isLoggedIn ? <Outlet /> : <Navigate to={`/login?redirect=${location.pathname}`} replace />;
};

export default AuthRoute;
