import React, { useEffect, useState } from "react";

import { Box, Button, CircularProgress } from "@mui/material";
import { Template01, Template02 } from "../../../Components/Templates/WeddingTemplates";
import { INVITATION_TYPES, TEMPLATES } from "../../../store/services/invitations/dto/invitations.dto";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { WeddingInvitationContainer } from "./styles";
import {
	useCreatInvitationFromTemplateMutation,
	useLazyGetWeddingInvitationQuery,
	useLazyValidateWeddingInvitationQuery
} from "../../../store/services/invitations/invitations.service";
import { useAppSelector } from "../../../store";
import { getFullState } from "../../../store/slices/auth/auth.slice";
import InvitationLoader from "../../../Components/InvtationLoader";
import NoInvitation from "../../../Components/NoInvitation";
import LockedInvitation from "../../../Components/LockedInvitation";
import { IError } from "../../../utils/getErrorMessage";
import { getFullInvitationsState } from "../../../store/slices/invitations/invitations.slice";
const WeddingInvitationTemplates = () => {
	const [selectedTemplateId, setSelectedTemplateId] = useState(1);
	const { selectedInvitation } = useAppSelector(getFullInvitationsState);
	const params = useParams();
	const id = params.id;
	const { isLoggedIn } = useAppSelector(getFullState);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const password = searchParams.get("password");
	const [fetchInvitation, { isLoading: isFetchingInvitation }] = useLazyGetWeddingInvitationQuery();

	const [callValidateInvitation, { isLoading: isValidatingInvitation, error: validationError }] =
		useLazyValidateWeddingInvitationQuery();

	const [callCreateInvitationFromTemplate, { isLoading: isCreatingTemplate }] =
		useCreatInvitationFromTemplateMutation();

	useEffect(() => {
		callValidateInvitation({
			id: id || "",
			password: password || ""
		}).then(result => {
			if (result.data?.canAccess) {
				fetchInvitation({
					id: id || "",
					password: password || ""
				});
			}
		});
	}, [id, password]);

	useEffect(() => {
		if (selectedInvitation) {
			setSelectedTemplateId(selectedInvitation.template_id || 1);
		}
	}, [selectedInvitation]);

	const templates: Record<number, React.ReactElement> = {
		[TEMPLATES.TEMPLATES01]: <Template01 data={selectedInvitation} />,
		[TEMPLATES.TEMPLATES02]: <Template02 data={selectedInvitation} />
	};

	const handleUseTemplate = () => {
		if (isLoggedIn && id) {
			callCreateInvitationFromTemplate({ template_id: id })
				.then(result => {
					navigate(`/manageWeddingInvitation/${result.data?.invitation_id}`);
				})
				.catch(err => console.log(err));
		} else {
			navigate(`/login?redirect=/weddingInvitation/${id}`);
		}
	};

	const getContent = () => {
		if (validationError && !isValidatingInvitation && !selectedInvitation) {
			const errorMessage = (validationError as IError).data.message;
			const isLocked = errorMessage.includes("password");
			if (isLocked) {
				return <LockedInvitation errorMessage={errorMessage} />;
			}
			return <NoInvitation />;
		}
		if (isValidatingInvitation || isFetchingInvitation || !selectedInvitation) {
			return <InvitationLoader />;
		}

		return (
			<>
				<Box className={"invitation-wrapper"}>{templates[selectedTemplateId]}</Box>
				{selectedInvitation?.type === INVITATION_TYPES.TEMPLATE && (
					<Button
						className={"invitation-buy-button"}
						variant={"contained"}
						color={"success"}
						onClick={handleUseTemplate}
						startIcon={isCreatingTemplate && <CircularProgress size={10} />}
						disabled={isCreatingTemplate}
					>
						Use Template
					</Button>
				)}
			</>
		);
	};

	return <WeddingInvitationContainer>{getContent()}</WeddingInvitationContainer>;
};

export default WeddingInvitationTemplates;
