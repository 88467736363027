import React, { useState, useRef } from "react";

import { Box, Typography, Container, Button, Divider, colors } from "@mui/material";
import { Instagram, FacebookOutlined, CopyrightOutlined } from "@mui/icons-material";

import WeddingList from "./WeddingList";
import FeaturesList from "./FeaturesList";

import Cake from "../../assets/icons/Cake.png";
import LoveICON from "../../assets/icons/Love.png";
import PhoneTemplate from "../../assets/imgs/phone_template.png";
import AppWorks from "../../assets/imgs/app-works.svg";
import Eye from "../../assets/imgs/Eye.png";
import Pencil from "../../assets/imgs/Pencil.png";
import Table from "../../assets/imgs/Table.png";
import StandsOut from "../../assets/imgs/stand_out.svg";
import VL_Logo from "../../assets/imgs/vl-logo-transparentBg.svg";

import {
	FeaturesSection,
	LandingContainer,
	LandingOverlay,
	MainSection,
	TemplateSection,
	WorksSection,
	WhoAreWeSection,
	FooterSection,
	MainSectionFade
} from "./styles";

enum TemplateTabs {
	WEDDING = "Weddings",
	BIRTHDAY = "Birthdays"
}
const Landing = () => {
	const [tab, setTab] = useState<TemplateTabs>(TemplateTabs.WEDDING);
	const invitationsRef = useRef<HTMLElement | null>(null);

	return (
		<LandingContainer>
			<LandingOverlay>
				<MainSectionFade />
				<Container>
					<MainSection>
						<Box display={"flex"} justifyContent={"center"} flexDirection={"column"}>
							<Typography variant={"h4"} sx={{ color: colors.common.white }}>
								Build fast, invite in style!
							</Typography>
							<Typography variant={"subtitle1"} sx={{ color: colors.common.white }}>
								Create digital invitations that stands out{" "}
							</Typography>
						</Box>
						<Box display={"flex"} justifyContent={"center"} position={"relative"} className={"phone_wrapper"}>
							<div className={"t1"} />
							<div className={"t2"} />
							<img src={PhoneTemplate} alt="phone template" className={"main_phone"} />
							<div className={"t3"} />
							<div className={"t4"} />
							<div className={"t5"} />
						</Box>
						<Button
							variant={"contained"}
							color={"primary"}
							size={"large"}
							onClick={() => invitationsRef.current?.scrollIntoView({ behavior: "smooth" })}
						>
							View Invitations
						</Button>
					</MainSection>
				</Container>
				<TemplateSection>
					<Container>
						<Box className={"categories"} ref={invitationsRef}>
							<Box
								className={`category ${tab === TemplateTabs.WEDDING && "active"}`}
								onClick={() => setTab(TemplateTabs.WEDDING)}
							>
								<img src={LoveICON} alt="Love icon" className={"icon"} />
								<Typography variant={"h6"}>{TemplateTabs.WEDDING}</Typography>
							</Box>
							<Box
								className={`category ${tab === TemplateTabs.BIRTHDAY && "active"}`}
								onClick={() => setTab(TemplateTabs.BIRTHDAY)}
							>
								<img src={Cake} alt="Cake icon" className={"icon"} />
								<Typography variant={"h6"}>{TemplateTabs.BIRTHDAY}</Typography>
							</Box>
						</Box>
						<Box mt={4} minHeight={600}>
							{tab === TemplateTabs.WEDDING ? <WeddingList /> : <Box></Box>}
						</Box>
					</Container>
				</TemplateSection>
				<WorksSection>
					<Container>
						<Box className={"works_wrapper"}>
							<Box m={2} className={"left_block"} display={"flex"} justifyContent={"center"}>
								<img src={AppWorks} alt="How it works image" className={"works_image"} />
							</Box>
							<Box m={2} className={"right_block"}>
								<Typography variant={"h4"} className={"title"}>
									How <span>VitesLink</span> work?
								</Typography>
								<Box mt={2}>
									<Box mt={4} display={"flex"} alignItems={"center"}>
										<Box mr={4}>
											<img src={Eye} alt="Eye icon" width={30} />
										</Box>
										<Box display={"flex"} flexDirection={"column"}>
											<Typography variant={"h6"} textAlign={"start"} fontWeight={"bold"}>
												Select Template
											</Typography>
											<Typography variant={"subtitle2"}>View, select and buy templates that suit your taste</Typography>
										</Box>
									</Box>
									<Box mt={4} display={"flex"} alignItems={"center"}>
										<Box mr={4}>
											<img src={Pencil} alt="Pencil icon" width={30} />
										</Box>
										<Box display={"flex"} flexDirection={"column"}>
											<Typography variant={"h6"} textAlign={"start"} fontWeight={"bold"}>
												Edit Template
											</Typography>
											<Typography variant={"subtitle2"}>
												Use our editor to Edit the invitation and <br />
												publish it when you’re ready
											</Typography>
										</Box>
									</Box>
									<Box mt={4} display={"flex"} alignItems={"center"}>
										<Box mr={4}>
											<img src={Table} alt="Dashboard icon" width={30} />
										</Box>
										<Box display={"flex"} flexDirection={"column"}>
											<Typography variant={"h6"} textAlign={"start"} fontWeight={"bold"}>
												Dashboard
											</Typography>
											<Typography variant={"subtitle2"}>
												Use our tailored dashboard to control your invitations and <br /> check the responses
											</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
					</Container>
				</WorksSection>
				<FeaturesSection>
					<Container>
						<FeaturesList />
					</Container>
				</FeaturesSection>
				<WhoAreWeSection>
					<Container>
						<Box display={"flex"} flexDirection={"column"}>
							<Box display={"flex"} justifyContent={"center"}>
								<Typography variant={"h4"} fontWeight={"bold"} className={"title"}>
									Who Are We!
								</Typography>
							</Box>
							<Box mt={4} display={"flex"} className={"who_are_we_wrapper"}>
								<Box>
									<Typography className={"who_description"}>
										<span>VitesLink</span> is a cutting-edge platform that provides digital invitation solutions for a
										wide variety of events, including weddings, engagements, birthdays, corporate meetings, and more.
										<br /> <br />
										Our mission is to offer a seamless, modern, and eco-friendly alternative to traditional paper
										invitations, providing customers with tools to design, customize, and distribute invitations
										effortlessly.
									</Typography>
								</Box>
								<Box display={"flex"} justifyContent={"center"}>
									<img src={StandsOut} alt="Stands out image" width={500} />
								</Box>
							</Box>
						</Box>
					</Container>
				</WhoAreWeSection>
				<FooterSection>
					<Container>
						<Box display={"Flex"} flexDirection={"column"}>
							<Box className={"top_footer"}>
								<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
									<img src={VL_Logo} alt="VL Logo" width={50} />
									<Box ml={1}>
										<Typography variant={"h6"} fontWeight={"bold"} color={"textPrimary"}>
											VitesLink
										</Typography>
									</Box>
								</Box>
								<Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
									<Box display={"flex"} alignItems={"center"} className={"email"}>
										<Typography variant={"subtitle2"}>info@viteslink.com</Typography>
									</Box>
									<Box mt={2}>
										<Instagram style={{ marginRight: "5px" }} className={"social"} />
										<FacebookOutlined className={"social"} />
									</Box>
								</Box>
							</Box>
							<Divider />
							<Box display={"flex"} alignItems={"center"} mt={2}>
								<Typography variant={"subtitle2"} style={{ fontSize: "12px" }}>
									Copywrite
								</Typography>
								<CopyrightOutlined style={{ marginLeft: "3px", marginRight: "3px" }} />
								<Typography style={{ marginLeft: "3px", marginRight: "3px", fontSize: "12px" }}>
									{new Date().getFullYear()}
								</Typography>
								<Typography variant={"subtitle1"} style={{ fontSize: "12px" }}>
									viteslink, All rights reserved
								</Typography>
							</Box>
						</Box>
					</Container>
				</FooterSection>
			</LandingOverlay>
		</LandingContainer>
	);
};

export default Landing;
