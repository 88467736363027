import styled from "styled-components";
import { Box, colors, darken } from "@mui/material";

export const AuthContainer = styled(Box)`
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	${props => props.theme.breakpoints.down("md")} {
		padding-top: 120px;
		height: unset;
	}
`;

export const AuthWrapper = styled(Box)`
	min-height: 500px;
	width: 70vw;
	display: flex;
	flex-flow: row wrap;
	box-shadow: 0 0 5px 2px ${colors.grey[300]};

	//& > div {
	//	flex-basis: 48%;
	//}
	.left_block {
		background: ${colors.common.white};
		padding: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		flex-grow: 1;
		.title {
			font-family: var(--openSans);
			font-weight: bold;
			font-size: 24px;
		}
		.input_wrapper {
			h6 {
				font-family: var(--openSans);
				font-weight: 500;
				margin-bottom: 5px;
			}
			p.Mui-error {
				max-width: 400px;
			}
		}
		button {
			border-radius: 30px;
			margin-top: 20px;
		}
	}
	.right_block {
		background: ${({ theme }) => theme.custom_palette.color1};
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		flex-grow: 1;
		padding: 20px;
		h4 {
			font-family: var(--openSans);
			color: ${colors.common.white};
			font-weight: bold;
		}
		h6 {
			font-family: var(--poppins);
			color: ${colors.common.white};
			margin-top: 20px;
		}
		button {
			margin-top: 20px;
			background: ${colors.common.white};
			color: ${colors.common.black};
			border-radius: 30px;
			&:hover {
				background: ${darken(colors.common.white, 0.1)};
			}
		}
	}

	${props => props.theme.breakpoints.down("md")} {
		width: 90vw;
		& > div {
			flex-basis: 100%;
		}
		.right_block {
			padding: 50px 10px;
		}
	}
`;
