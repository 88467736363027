import React from "react";
import { Box, Button, Typography, Skeleton, useMediaQuery } from "@mui/material";

import ComingSoon from "../../../assets/imgs/coming_soon.png";
import { round } from "lodash";
import { useGetWeddingInvitationsQuery } from "../../../store/services/invitations/invitations.service";
import { formatCurrency } from "../../../utils/formatCurrency";
import { useNavigate } from "react-router-dom";
import theme from "../../../theme";

const WeddingList = () => {
	const { data, isLoading } = useGetWeddingInvitationsQuery({});
	const navigate = useNavigate();
	const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));
	const { invitations } = data || {};

	if (isLoading) {
		const loadingArray = new Array(6).fill(0);
		return (
			<Box display="flex" style={{ flexFlow: "row wrap" }} gap={2}>
				{loadingArray.map((_, index) => (
					<Box key={index} width={isSmallerScreen ? "100%" : "250px"}>
						<Skeleton variant={"rectangular"} height={"300px"} />
						<Skeleton variant={"text"} sx={{ fontSize: "40px" }} />
						<Skeleton variant={"text"} />
					</Box>
				))}
			</Box>
		);
	}
	return (
		<Box className={"templates_list"}>
			{invitations?.map(t => (
				<Box className={"template"} key={t._id} onClick={() => navigate(`/weddingInvitation/${t._id}`)}>
					{!!t.discount && (
						<Box className={"discount"}>
							<Typography variant={"subtitle1"}>
								Save {t.is_discount_percentage ? `${t.discount}%` : `${formatCurrency(t.discount)}`}
							</Typography>
						</Box>
					)}
					{t.is_published && (
						<Box className={"coming_soon_over_lay"}>
							<img src={ComingSoon} alt="comming soon" />
						</Box>
					)}

					<img src={t.thumbnail} alt="template" className={"thumbnail"} />
					<Box mt={1}>
						<Typography variant={"h6"} fontSize={16}>
							{t.name}
						</Typography>
					</Box>
					<Box mt={1} display={"flex"} alignItems={"center"} width={"100%"} position={"relative"}>
						{t.discount ? (
							<>
								<Typography variant={"subtitle1"} className={"price"}>
									${round(t.price - (t.is_discount_percentage ? ((t.price * t.discount) / 100, 2) : t.discount))}
								</Typography>
								<Typography variant={"subtitle2"} className={"old_price"}>
									${round(t.price, 2)}
								</Typography>
								<Typography variant={"subtitle2"} className={"off_amount"}>
									{t.is_discount_percentage ? `${t.discount}%` : `${formatCurrency(t.discount)}`} OFF
								</Typography>
							</>
						) : (
							<Typography variant={"subtitle1"} className={"price"}>
								${round(t.price, 2)}
							</Typography>
						)}
					</Box>
					<Box width={"100%"} mt={2}>
						<Button fullWidth variant={"outlined"} color={"primary"}>
							View
						</Button>
					</Box>
				</Box>
			))}
		</Box>
	);
};

export default WeddingList;
