import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { Template01, Template02 } from "../../Components/Templates/WeddingTemplates";

import { TEMPLATES } from "../../store/services/invitations/dto/invitations.dto";

import { useGetUserWeddingInvitationQuery } from "../../store/services/invitations/invitations.service";
import { useParams } from "react-router-dom";

import { WeddingInvitationContainer } from "./styles";

import InvitationLoader from "../../Components/InvtationLoader/InvitationLoader";
import NoInvitation from "../../Components/NoInvitation";

const WeddingInvitation = () => {
	const [selectedTemplateId, setSelectedTemplateId] = useState(1);
	const params = useParams();
	const id = params.id;

	const { data, isLoading, error } = useGetUserWeddingInvitationQuery({ id: id || "" });

	useEffect(() => {
		if (data) {
			setSelectedTemplateId(data.invitation.template_id);
		}
	}, [data]);

	const templates: Record<number, React.ReactElement> = {
		[TEMPLATES.TEMPLATES01]: <Template01 data={data?.invitation} />,
		[TEMPLATES.TEMPLATES02]: <Template02 data={data?.invitation} />
	};

	const getContent = () => {
		if (error && !isLoading) {
			return <NoInvitation />;
		}
		if (isLoading) {
			return <InvitationLoader />;
		}

		return <Box className={"invitation-wrapper"}>{templates[selectedTemplateId]}</Box>;
	};

	return <WeddingInvitationContainer pt={7}>{getContent()}</WeddingInvitationContainer>;
};

export default WeddingInvitation;
