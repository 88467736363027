export interface Invitations {
	_id: string;
	name: string;
	type: number;
	description: string;
	accepted: number;
	price: number;
	discount: number;
	is_discount_percentage: boolean;
	is_published: boolean;
	thumbnail: string;
	template_id: number;
}

export enum TEMPLATES {
	TEMPLATES01 = 1,
	TEMPLATES02 = 2
}

export enum INVITATION_TYPES {
	TEMPLATE = 1,
	USER = 2
}
