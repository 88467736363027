import { api } from "../index";
import { Invitations } from "./dto/invitations.dto";

const WEDDING_INVITATION_ENDPOINT = "/wedding-invitations";
const WEDDING_INVITATION_ENDPOINT_MANAGEMENT = "/wedding-invitations/manage";

export const InvitationsAPI = api.injectEndpoints({
	endpoints: build => ({
		getWeddingInvitations: build.query<{ invitations: Invitations[] }, { filter?: string }>({
			query: ({ filter }) => ({ url: `${WEDDING_INVITATION_ENDPOINT}`, params: { filter }, method: "GET" })
		}),
		getUserWeddingInvitations: build.query<{ invitations: Invitations[] }, { filter?: string }>({
			query: ({ filter }) => ({
				url: `${WEDDING_INVITATION_ENDPOINT_MANAGEMENT}/list`,
				params: { filter },
				method: "GET"
			}),
			providesTags: ["Invitations"]
		}),
		validateWeddingInvitation: build.query<{ canAccess: boolean }, { id: string; password?: string }>({
			query: ({ id, password }) => ({
				url: `${WEDDING_INVITATION_ENDPOINT}/validate/${id}${password ? `?password=${password}` : ""}`,
				method: "GET"
			})
		}),
		getWeddingInvitation: build.query<{ invitation: Invitations }, { id: string; password?: string }>({
			query: ({ id, password }) => ({
				url: `${WEDDING_INVITATION_ENDPOINT}/${id}${password ? `?password=${password}` : ""}`,
				method: "GET"
			})
		}),
		getUserWeddingInvitation: build.query<{ invitation: Invitations }, { id: string }>({
			query: ({ id }) => ({
				url: `${WEDDING_INVITATION_ENDPOINT_MANAGEMENT}/${id}`,
				method: "GET"
			})
		}),
		creatInvitationFromTemplate: build.mutation<{ success: boolean; invitation_id: string }, { template_id: string }>({
			query: body => ({ url: `${WEDDING_INVITATION_ENDPOINT_MANAGEMENT}/create-from-template`, method: "POST", body }),
			invalidatesTags: ["Invitations"]
		})
	})
});

export const {
	useGetWeddingInvitationsQuery,
	useLazyGetWeddingInvitationQuery,
	useGetWeddingInvitationQuery,
	useCreatInvitationFromTemplateMutation,
	useLazyValidateWeddingInvitationQuery,
	useGetUserWeddingInvitationsQuery,
	useGetUserWeddingInvitationQuery
} = InvitationsAPI;
