import React from "react";
import { Box, Button, CircularProgress, TextField, Typography, useMediaQuery } from "@mui/material";
import { useUpdateAccountPasswordMutation } from "../../../store/services/auth/auth.service";
import { useFormik } from "formik";
import { updatePasswordAccountSchema } from "../../Auth/schemas";
import { toast } from "react-toastify";
import { notifyError } from "../../../utils/NotifyError";
import { IError } from "../../../utils/getErrorMessage";
import theme from "../../../theme";

const Security = () => {
	const [callUpdatePassword, { isLoading }] = useUpdateAccountPasswordMutation();
	const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));

	const {
		handleSubmit,
		handleChange,
		errors,
		values: { oldPassword, password, confirmPassword },
		dirty,
		isValid,
		resetForm
	} = useFormik({
		validationSchema: updatePasswordAccountSchema,
		initialValues: {
			oldPassword: "",
			password: "",
			confirmPassword: ""
		},
		onSubmit: values => {
			callUpdatePassword({ oldPassword: values.oldPassword, password: values.confirmPassword }).then(
				({ data, error }) => {
					if (data) {
						toast.success("Password updated successfully.");
						resetForm();
					}
					if (error) {
						notifyError(error as IError);
					}
				}
			);
		}
	});

	return (
		<form onSubmit={handleSubmit} style={{ width: isSmallerScreen ? "100%" : "55%" }}>
			<Box mb={2} className={"input_wrapper"}>
				<Typography variant={"subtitle1"} fontWeight={"bold"}>
					Old Password
				</Typography>
				<TextField
					fullWidth
					type={"password"}
					value={oldPassword}
					name={"oldPassword"}
					onChange={handleChange}
					error={!!errors.oldPassword}
					helperText={errors.oldPassword}
				/>
			</Box>
			<Box mb={2} className={"input_wrapper"}>
				<Typography variant={"subtitle1"} fontWeight={"bold"}>
					New Password
				</Typography>
				<TextField
					fullWidth
					type={"password"}
					value={password}
					name={"password"}
					onChange={handleChange}
					error={!!errors.password}
					helperText={errors.password}
				/>
			</Box>
			<Box mb={2} className={"input_wrapper"}>
				<Typography variant={"subtitle1"} fontWeight={"bold"}>
					Confirm Password
				</Typography>
				<TextField
					fullWidth
					type={"password"}
					value={confirmPassword}
					name={"confirmPassword"}
					onChange={handleChange}
					error={!!errors.confirmPassword}
					helperText={errors.confirmPassword}
				/>
			</Box>
			<Box width={"100%"}>
				<Button
					fullWidth
					variant={"contained"}
					type="submit"
					disabled={(dirty && !isValid) || isLoading}
					startIcon={isLoading && <CircularProgress size={10} />}
				>
					Update Password
				</Button>
			</Box>
		</form>
	);
};

export default Security;
