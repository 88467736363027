import React from "react";
import { Avatar, Box, Button, ButtonBase, Typography, colors, Menu, MenuItem, useTheme } from "@mui/material";

import VL_Logo from "../../assets/imgs/vl-logo-transparentBg.svg";

import { HeaderWrapper } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../store";
import { getFullState, logOut } from "../../store/slices/auth/auth.slice";
import { googleLogout } from "@react-oauth/google";
import { AccountType } from "../../store/services/auth/dto/auth.dto";
import { api } from "../../store/services";

const Header = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const { isLoggedIn, user } = useAppSelector(getFullState);
	const dispatch = useAppDispatch();
	const pathFilterList = ["/login", "/signup", "/resetPassword"];
	const hideLoginControl = pathFilterList.some(p => location.pathname.includes(p));
	const isLandingPage = location.pathname === "/";
	const loginButtonColor = isLandingPage ? theme.palette.primary.dark : theme.palette.common.white;
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogOut = () => {
		if (user?.account_type === AccountType.SOCIAL) {
			googleLogout();
		}
		dispatch(logOut());
		dispatch(api.util.resetApiState());
		localStorage.clear();
		navigate("/");
		handleClose();
	};
	const handleMyAccount = () => {
		navigate("/myAccount");
		handleClose();
	};

	return (
		<HeaderWrapper $isLandingPage={isLandingPage}>
			<Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} px={2} py={1}>
				<Box
					display={"flex"}
					flexDirection={"row"}
					alignItems={"center"}
					onClick={() => navigate("/")}
					style={{ cursor: "pointer" }}
				>
					<img src={VL_Logo} alt="VL Logo" width={40} />
					<Box ml={1}>
						<Typography variant={"h6"} fontWeight={"bold"} color={"textPrimary"} sx={{ color: colors.common.white }}>
							VitesLink
						</Typography>
					</Box>
				</Box>
				{!hideLoginControl && (
					<Box>
						{isLoggedIn && user ? (
							<div>
								<ButtonBase onClick={handleClick}>
									<Avatar sx={{ bgcolor: colors.deepOrange[500], fontSize: "16px" }}>
										{user?.firstName[0] + user?.lastName[0]}
									</Avatar>
								</ButtonBase>
								<Menu
									id="basic-menu"
									anchorEl={anchorEl}
									open={!!anchorEl}
									onClose={handleClose}
									MenuListProps={{
										"aria-labelledby": "basic-button"
									}}
								>
									<MenuItem onClick={handleMyAccount}>My account</MenuItem>
									<MenuItem onClick={handleLogOut}>Logout</MenuItem>
								</Menu>
							</div>
						) : (
							<Button
								variant={"outlined"}
								sx={{
									color: loginButtonColor,
									borderColor: loginButtonColor
								}}
								onClick={() => navigate("/login")}
							>
								Login
							</Button>
						)}
					</Box>
				)}
			</Box>
		</HeaderWrapper>
	);
};

export default Header;
