import React from "react";

import { ErrorBoundary } from "react-error-boundary";

import ErrorFallback from "../ErrorFallback";

const AppRoot = ({ children }: { children: React.ReactNode }) => {
	return <ErrorBoundary fallback={<ErrorFallback />}>{children}</ErrorBoundary>;
};

export default AppRoot;
